import { ThemeProvider } from "@emotion/react";
import GlobalTheme from "../theme/GlobalTheme";
import {
    Box,
    Card, CircularProgress,
    Divider,
    Grid,
    responsiveFontSizes,
    Skeleton,
    Snackbar,
    SnackbarContent,
    Typography,
    useMediaQuery
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import PhoneIcon from '@mui/icons-material/Phone';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Footer from "../components/Footer";

import { motion } from "framer-motion";
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, timelineItemClasses } from "@mui/lab";
import ParallaxParticles from "../components/ParallaxParticles";
import TimelineOppositeContent, {timelineOppositeContentClasses} from "@mui/lab/TimelineOppositeContent";
import CelebrationIcon from "@mui/icons-material/Celebration";
import React from "react";
import moment from 'moment';

export interface Event {
    name: string;
    date: string;
    team: string;
}

const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0,
});

export default function Quote() {
    const { id, event } = useParams();
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [location, setLocation] = useState("");
    const [social, setSocial] = useState("");
    const [weddingPackage, setWeddingPackage] = useState("");
    const [foundersPackage, setFoundersPackage] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [notes, setNotes] = useState("");
    const [events, setEvents] = useState<Event[]>([])
    const [expiry, setExpiry] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const targetRef = useRef<HTMLDivElement | null>(null);
    const bigDevice = useMediaQuery(GlobalTheme.breakpoints.up('md'));

    useEffect(() => {
        if (!bigDevice) {
            targetRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [loading])

    useEffect(() => {
        axios.post("/signature-moments-data-ingest", {
            action: 'GET_QUOTE_DETAILS',
            quote_no: id,
            email: "1",
            password: "1"
        }).then(response => {
            if (response.status === 200) {
                setName(response.data[1]);
                setPhone(response.data[2]);
                setEmail(response.data[3]);
                setDate(response.data[4]);
                setLocation(response.data[5]);
                setSocial(response.data[6]);
                setWeddingPackage(response.data[7]);
                setFoundersPackage(response.data[8].toLowerCase() == "yes");
                setDiscount(Number.parseInt(response.data[9].replaceAll(",", "")));
                setTotal(Number.parseInt(response.data[10].replaceAll(",", "")));
                setNotes(response.data[11]);
                let list: Event[] = [];
                for (let i = 12; i < 42; i = i+3) {
                    let event: Event = {
                        name: response.data[i],
                        date: response.data[i+1],
                        team: response.data[i+2],
                    }
                    list.push(event);
                }
                setEvents(list);
                let expired = moment().startOf('day').isAfter(moment(response.data[42], 'DD-MM-YYYY'));
                setExpiry(expired);
                if (expired) {
                    setShowAlert(true);
                    setAlertMessage("Quote has expired!");
                } else if (event !== response.data[1]) {
                    setExpiry(true);
                    setShowAlert(true);
                    setAlertMessage("Quote not found!");
                } else {
                    setLoading(false);
                    setShowAlert(false);
                    setAlertMessage("");
                }
            } else {
                setExpiry(true);
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
            }
        }).catch(error => {
            setExpiry(true);
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
        })
    }, []);

    return (
        <ThemeProvider theme={responsiveFontSizes(GlobalTheme)}>
            <Grid
                container
                component="main"
                textAlign={"center"}
                sx={{
                    backgroundColor: "primary.main",
                    minHeight: "100vh"
                }}
            >
                <Grid item xs={12} md={6} p={0} alignContent={"center"}>
                    <ParallaxParticles/>
                </Grid>
                {
                    loading ? bigDevice ? <Grid item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <Grid container justifyContent={"center"} px={3}>
                            <Grid item xs={6}>
                                <Skeleton height={200} />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton height={150} />
                            </Grid>
                            <Grid item xs={8}>
                                <Skeleton height={100} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={6} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                        </Grid>
                    </Grid> : null : <Grid ref={targetRef} item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                            viewport={{ once: true }}
                        >
                            <Grid container>
                                <Grid item xs={12} pt={2}>
                                    <Typography variant="h3" fontWeight={"700"} p={1}>{name}</Typography>
                                </Grid>
                                <Grid item xs={12} py={2} sx={{ textAlign: '-webkit-center' }}>
                                    <motion.div
                                        initial={{ x: -300, opacity: 0 }}
                                        whileHover={{ scale: 1.1 }}
                                        whileInView={{
                                            x: 0,
                                            opacity: 1,
                                            transition: {
                                                type: "spring",
                                                bounce: 0.4,
                                                duration: 2
                                            }
                                        }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <Card elevation={16} sx={{ width: '95%', maxWidth: 500, background: "black", borderRadius: 3 }}>
                                            <Grid container>
                                                {
                                                    foundersPackage ? <Grid item xs={12} pt={2}>
                                                        <motion.div initial={{
                                                            scale: 0,
                                                            opacity: 0,
                                                        }} whileInView={{
                                                            opacity: 1,
                                                            scale: 1.0,
                                                            transition: {
                                                                duration: 1.5
                                                            }
                                                        }}>
                                                            <Typography bgcolor={"white"} fontWeight={700}
                                                                        fontSize="1rem" sx={{borderRadius: 2, width: 'fit-content', px: 2, py: 0.25}}>
                                                                Founders Package
                                                            </Typography>
                                                        </motion.div>
                                                    </Grid> : null
                                                }
                                                <Grid item xs={6} p={2} pt={1} alignContent="center">
                                                    {
                                                        weddingPackage !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <Typography fontWeight={700} variant="h6" color={"secondary"}>Package</Typography>
                                                                <Typography variant="body1" color={"secondary"}>{weddingPackage}</Typography>
                                                            </Grid> : null
                                                    }
                                                    <Grid item xs={12} py={1}>
                                                        <Typography fontWeight={700} variant="h6" color={"secondary"}>Cost</Typography>
                                                        <Typography variant="body1" color={"secondary"}>{formatter.format(total + discount)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} py={1}>
                                                        {
                                                            discount != 0 ? <>
                                                                    <Typography fontWeight={700} variant="h6" color={"secondary"}>Your special price</Typography>
                                                                    <Typography component="span" variant="body1" color={"secondary"} sx={{textDecoration: "line-through"}}>
                                                                        {formatter.format(total + discount)}
                                                                    </Typography>
                                                                    <Typography component="span" variant="body1" color={"secondary"}>
                                                                        {" " + formatter.format(total)}
                                                                    </Typography>
                                                                </> :
                                                                <>
                                                                    <Typography fontWeight={700} variant="h6" color={"secondary"}>Total</Typography>
                                                                    <Typography component="span" variant="body1" color={"secondary"}>
                                                                        {formatter.format(total)}
                                                                    </Typography>
                                                                </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} p={2} alignContent="center">
                                                    {
                                                        date !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <EventIcon color={"secondary"} />
                                                                <Typography variant="body1" color={"secondary"}>{date}</Typography>
                                                            </Grid> : null
                                                    }
                                                    {
                                                        location !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <PlaceIcon color={"secondary"} />
                                                                <Typography color={"secondary"}>{location}</Typography>
                                                            </Grid> : null
                                                    }
                                                    {
                                                        phone !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <PhoneIcon color={"secondary"} />
                                                                <Typography color={"secondary"}>{phone}</Typography>
                                                            </Grid> : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} pb={1} py={1} px={{ xs: 2, md: 4, lg: 8, xl: 12 }} >
                                    <Typography>{notes}</Typography>
                                </Grid>
                                <Grid item xs={12} py={1} px={{ xs: 2, md: 4, lg: 8, xl: 12 }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} mb={-1}>
                                    <Typography variant="h4" fontWeight={"700"} p={1}>Timeline</Typography>
                                </Grid>
                                <Grid item xs={12} maxHeight={bigDevice ? '40vh' : '100vh'} overflow="scroll">
                                    <Timeline sx={{
                                        [`& .${timelineOppositeContentClasses.root}`]: {
                                            flex: bigDevice ? 1 : 0.5,
                                        },
                                    }}>
                                        {events.map((event, index) => {
                                            return event.name !== "" ? <motion.div
                                                key={index}
                                                initial={{ opacity: 0, scale: 0.1 }}
                                                whileInView={{
                                                    opacity: 1,
                                                    scale: 1.0,
                                                    transition: {
                                                        duration: 1
                                                    }
                                                }}
                                                whileTap={{ scale: 0.9 }}
                                            >
                                                <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0' }}
                                                        align="right"
                                                        variant="body2"
                                                        color="primary"
                                                    >
                                                        {event.date}
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary">
                                                            <CelebrationIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ m: "auto 0" }}>
                                                        <Typography variant="h6" component="span">
                                                            {event.name}
                                                        </Typography>
                                                        <Typography variant="body1" fontSize={14} color="grey">
                                                            {event.team.split("\n").join(',').split(',').map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </motion.div> : null;
                                        })}
                                    </Timeline>
                                </Grid>
                                <Grid item xs={12} px={{ xs: 2 }} py={2} sx={{textAlign: "-webkit-center"}}>
                                    <Typography maxWidth={600} variant="subtitle1" color="grey" fontSize={14}>
                                        Please note, this is our proposal, but you are welcome to customize it as needed.
                                        <br/>
                                        If you require any assistance, feel free to contact us <Link to="https://wa.me/919361271285" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>here</Link> for further customization.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                }
            </Grid>
            {
                !loading ?
                    <Grid container sx={{ backgroundColor: "black", pt: { sm: 1, md: 4, lg: 8 } }}>
                        <Grid item xs={12} lg={6} textAlign={'center'} sx={{ px: { xs: 2, md: 8, lg: 16 } }}>
                            <motion.div
                                initial={{
                                    y: 300,
                                }}
                                whileInView={{
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        bounce: 0.4,
                                        duration: 0.8
                                    }
                                }}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                viewport={{ once: true }}
                            >
                                <Card sx={{ mt: { xs: 4, md: 6, lg: 8, borderRadius: 15 } }}>
                                    <Typography variant={'h4'} pt={3} fontWeight={700}>Deliverables</Typography>
                                    <Timeline sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                        px: { sm: 2, md: 3, lg: 4 }
                                    }}>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>A set of 300 edited candid photos will be deliverd through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>All traditional photos will be deliverd through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>A candid video narrating the story of the event will be delivered through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>A traditional video covering the entire event will be delivered through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                            </TimelineSeparator>
                                            <TimelineContent>The delivery of the outputs will take at least 90 days.</TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} lg={6} textAlign={'center'} sx={{ px: { xs: 2, md: 8, lg: 16 } }}>
                            <motion.div
                                initial={{
                                    y: 300,
                                }}
                                whileInView={{
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        bounce: 0.4,
                                        duration: 0.8
                                    }
                                }}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                viewport={{ once: true }}
                            >
                                <Card sx={{ mt: { xs: 4, md: 6, lg: 8, borderRadius: 15 } }}>
                                    <Typography variant={'h4'} pt={3} fontWeight={700}>Payment Terms</Typography>
                                    <Timeline sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                        px: { sm: 2, md: 3, lg: 4 }
                                    }}>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>60% of the package cost must be paid as a non-refundable booking advance.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>40% of the package cost must be paid on the event date.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>Post-processing work will begin only after full payment is completed, and this is non-negotiable.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>100% of the album package must be paid once the album design is finalized.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                            </TimelineSeparator>
                                            <TimelineContent>The team will handle travel and accommodation if the event location is within Tamil Nadu.</TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Card>
                            </motion.div>
                        </Grid>
                    </Grid>
                    : null
            }
            {
                loading && !expiry ? <Box sx={{
                    zIndex: 1,
                    backdropFilter: 'blur(4px)',
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress color="secondary"/>
                </Box> : null
            }
            {
                !loading ? <Footer /> : null
            }
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={10000}
                open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
        </ThemeProvider>
    )
}