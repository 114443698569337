import { ThemeProvider } from "@emotion/react";
import {
    Box, Button,
    Card,
    Checkbox, Chip,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Snackbar,
    SnackbarContent,
    TextField,
    Typography
} from "@mui/material";
import GlobalTheme from "../theme/GlobalTheme";
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";
import SnowParticles from "../components/SnowParticles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const packagesList = [
    'Luminous Moments',
    'Radiant Moments',
    'Summit Moments'
];

const addonsList = [
    'Candid Photo',
    'Candid Video',
    'Traditional Photo',
    'Traditional Video',
    'LED Wall',
    'Mixer Unit',
    'Drone',
    'Pre/Post Wedding Candid Photo',
    'Pre/Post Wedding Candid Video',
    'AI Photo Sharing',
    '360 Setup',
    'Mirror Selfie',
    'Instant Print*',
];

const cardVariants = {
    initial: { scale: 1.00, boxShadow: "0 0 0 rgba(255, 255, 255, 255)", transition: { duration: 0.25 } },
    whileHover: { scale: 1.05, boxShadow: "0 0 20px rgba(255, 255, 255, 1)", transition: { duration: 0.25 } },
    whileTap: { scale: 1.05 },
    selected: { scale: 1.05, boxShadow: "0 0 20px rgba(255, 255, 255, 1)", transition: { duration: 0.25 } },
    notSelected: { scale: 1.00, boxShadow: "0 0 0 rgba(255, 255, 255, 255)", transition: { duration: 0.25 } },
};

export default function WeddingEnquiry() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState("");
    const [weddingPackage, setWeddingPackage] = useState('');
    const [addons, setAddons] = useState<string[]>([]);

    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleSendEnquiry = () => {
        axios.post("/signature-moments-data-ingest", {
            action: 'CREATE_WEDDING_ENQUIRY',
            name: name,
            phone: phone,
            date: date,
            package: weddingPackage,
            addons: addons.toString()
        }).then(response => {
            setLoading(false);
            if (response.status === 200) {
                setAlertMessage("Success!");
                setShowAlert(true);
            } else {
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
                setSubmitDisabled(false);
            }
        }).catch(error => {
            setLoading(false);
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
            setSubmitDisabled(false);
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <ThemeProvider theme={GlobalTheme}>
            <Grid
                container
                component="main"
                textAlign={"center"}
                sx={{
                    backgroundColor: "primary.main",
                }}
            >
                <div style={{ position: "absolute" }}>
                    <SnowParticles />
                </div>
                <Grid item xs={12} p={2} pt={6} zIndex={1}>
                    <img src={"logo.png"} width={"16%"} style={{ minWidth: "180px" }} alt="logo" />
                </Grid>
                <Grid item xs={12} p={2} mb={3} zIndex={1}>
                    <Typography variant={'body1'} color={'primary.light'} fontWeight={700} fontSize="2.5rem">
                        <motion.div initial={{ y: '-100%', opacity: 0}} animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 1.5 }}>
                            Wedding Packages
                        </motion.div>
                    </Typography>
                </Grid>
                <Grid item xs={12} p={2} zIndex={1} pb={3}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} lg={3}>
                            <motion.div initial="initial" whileHover="whileHover" transition={{ duration: 0.2 }}
                                        whileTap="whileTap" variants={cardVariants}
                                        animate={weddingPackage === packagesList[2] ? "selected" : "notSelected"}
                                        style={{height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '1rem'}}>
                                <Card sx={{borderRadius: 4, height: 'inherit', cursor: 'pointer'}}
                                      onClick={() => setWeddingPackage(packagesList[2])}>
                                    <Box p={3} pb={2}>
                                        <Typography color={weddingPackage === packagesList[2] ? "white" : "black"}
                                                    bgcolor={weddingPackage === packagesList[2] ? "black" : "white"}
                                                    fontWeight={700} fontSize="2rem" sx={{borderRadius: 3}}>
                                            Summit Moments
                                        </Typography>
                                        <Divider textAlign="right" sx={{ pt: 3, pb: 1 }}>
                                            {
                                                weddingPackage === packagesList[2] ?
                                                    <Typography variant={"h5"} fontWeight={500}>
                                                        ₹ 6,75,000
                                                    </Typography> : null
                                            }
                                        </Divider>
                                        <List aria-label="timeless-elegance-package">
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="3 Candid Photographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="3 Candid Videographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="2 Traditional Photographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="2 Traditional Videographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Pre and Post Wedding Session" secondary="Pre-wedding session in our studio (Slate Pencil) and Post-wedding session outdoors." />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Drone" secondary="Two sessions." />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Premium 35 Sheet Album" secondary="Complimentary." />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="AI Photo Sharing" secondary="Complimentary." />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="LED Wall 12x8" secondary="Complimentary." />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <motion.div initial="initial" whileHover="whileHover" transition={{ duration: 0.2 }}
                                        whileTap="whileTap" variants={cardVariants}
                                        animate={weddingPackage === packagesList[1] ? "selected" : "notSelected"}
                                        style={{height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '1rem'}}>
                                <Card sx={{borderRadius: 4, height: 'inherit', cursor: 'pointer'}}
                                      onClick={() => setWeddingPackage(packagesList[1])}>
                                    <Box p={3}>
                                        <Typography color={weddingPackage === packagesList[1] ? "white" : "black"}
                                                    bgcolor={weddingPackage === packagesList[1] ? "black" : "white"}
                                                    fontWeight={700} fontSize="2rem" sx={{borderRadius: 3}}>
                                            Radiant Moments
                                        </Typography>
                                        <Divider textAlign="right" sx={{ pt: 3, pb: 1 }}>
                                            {
                                                weddingPackage === packagesList[1] ?
                                                    <Typography variant={"h5"} fontWeight={500}>
                                                        ₹ 4,00,000
                                                    </Typography> : null
                                            }
                                        </Divider>
                                        <List aria-label="enchanted-moments-package">
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="2 Candid Photographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="2 Candid Videographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="2 Traditional Photographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="2 Traditional Videographers" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Pre or Post Wedding Session" secondary="Pre-wedding/Post-wedding session in our studio (Slate Pencil)." />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Drone" secondary="One session." />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Classic 25 Sheet Album" secondary="Complimentary." />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <motion.div initial="initial" whileHover="whileHover" transition={{ duration: 0.2 }}
                                        whileTap="whileTap" variants={cardVariants}
                                        animate={weddingPackage === packagesList[0] ? "selected" : "notSelected"}
                                        style={{height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '1rem'}}>
                                <Card sx={{borderRadius: 4, height: 'inherit', cursor: 'pointer'}}
                                      onClick={() => setWeddingPackage(packagesList[0])}>
                                    <Box p={3}>
                                        <Typography color={weddingPackage === packagesList[0] ? "white" : "black"}
                                                    bgcolor={weddingPackage === packagesList[0] ? "black" : "white"}
                                                    fontWeight={700} fontSize="2rem" sx={{borderRadius: 3}}>
                                            Luminous Moments
                                        </Typography>
                                        <Divider textAlign="right" sx={{ pt: 3, pb: 1 }}>
                                            {
                                                weddingPackage === packagesList[0] ?
                                                    <Typography variant={"h5"} fontWeight={500}>
                                                        ₹ 2,00,000
                                                    </Typography> : null
                                            }
                                        </Divider>
                                        <List aria-label="eternal-bliss-package">
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Candid Photographer" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Candid Videographer" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Traditional Photographer" />
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Traditional Videographer" />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Card>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} zIndex={1} p={3}>
                    <Typography variant="body2" color="primary.light">
                        This pricing covers only the Wedding and Reception only (2 Sessions). Additional sessions will incur extra costs. Please contact our team to customize your package.
                    </Typography>
                </Grid>

                <Grid item xs={0} md={6} zIndex={1} sx={{
                    height: '100vh',
                    alignItems: 'center',
                    textAlign: '-webkit-center',
                    display: { xs: 'none', md: 'grid' },
                    backdropFilter: 'blur(10px)',
                    background: 'rgba(0,0,0,0.1)',
                }}>
                        <Grid>
                            <Grid item xs={9} sx={{ p: 3 }}>
                                <Typography
                                    variant={'h6'} fontWeight={100}
                                    color={"primary.light"}
                                >
                                    Embark on a captivating visual voyage with our photography and
                                    videography services, where we weave your special moments into an
                                    enchanting narrative.
                                    <br></br>
                                    <br></br>
                                    Our diverse package options ensure that every detail, every emotion,
                                    and every cherished instant is beautifully preserved for a lifetime.
                                    Whether you seek the elegance of photography, the motion of
                                    videography, or the perfect blend of both, our packages cater to
                                    your desires.
                                    <br></br>
                                    <br></br>
                                    Choose not just a service, but an experience that mirrors your
                                    unique story. Explore our packages and let us transform your moments
                                    into a masterpiece.
                                </Typography>
                            </Grid>
                        </Grid>
                </Grid>
                <Grid item xs={12} md={6} zIndex={1}
                    sx={{
                        backgroundColor: 'primary.light',
                        alignSelf: 'center',
                        display: 'block',
                    }}>
                        <Box>
                            <Grid container rowSpacing={{ xs: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ p: { xs: 2, md: 4 } }} textAlign={'left'}>
                                <Grid item>
                                    <Typography variant={'h4'} fontWeight={500} sx={{ pt: 2, pb: 2 }}>
                                        Send us an enquiry
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Name" type='text' variant="outlined" fullWidth value={name} onChange={(event) => {
                                        setName(event.target.value as string);
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Phone" type='text' variant="outlined" fullWidth value={phone} onChange={(event) => {
                                        setPhone(event.target.value as string);
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Date" type='text' variant="outlined" fullWidth value={date} onChange={(event) => {
                                        setDate(event.target.value as string);
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl sx={{ width: '-webkit-fill-available' }}>
                                        <InputLabel id="package-label">Package</InputLabel>
                                        <Select
                                            labelId="package-label"
                                            id="package"
                                            value={weddingPackage}
                                            label="Package"
                                            onChange={(event) => {
                                                setWeddingPackage(event.target.value as string);
                                            }}
                                        >
                                            {packagesList.map((plan) => (
                                                <MenuItem value={plan}>{plan}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl sx={{ width: '-webkit-fill-available' }}>
                                        <InputLabel id="add-ons-label">Add-ons</InputLabel>
                                        <Select
                                            labelId="add-ons-label"
                                            id="add-ons"
                                            multiple
                                            value={addons}
                                            onChange={(event: SelectChangeEvent<typeof addons>) => {
                                                const { target: { value }, } = event;
                                                setAddons(typeof value === 'string' ? value.split(',') : value);
                                            }}
                                            input={<OutlinedInput label="Add-ons" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {addonsList.map((addon) => (
                                                <MenuItem key={addon} value={addon}>
                                                    <Checkbox checked={addons.indexOf(addon) > -1} />
                                                    <ListItemText primary={addon} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div style={{ textAlign: 'right', paddingBottom: '2rem', paddingTop: '1rem' }}>
                                <LoadingButton variant="contained" loading={loading} disabled={submitDisabled}
                                    sx={{ width: '45%', height: '4rem', borderRadius: 0 }} onClick={() => {
                                        if (name === null || name === "") {
                                            setAlertMessage("Name is required!");
                                            setShowAlert(true);
                                        } else if (phone === null || phone === "") {
                                            setAlertMessage("Phone is required!");
                                            setShowAlert(true);
                                        } else if (date === null || date === "") {
                                            setAlertMessage("Date is required!");
                                            setShowAlert(true);
                                        } else if (weddingPackage === null || weddingPackage === "") {
                                            setAlertMessage("Package is required");
                                            setShowAlert(true);
                                        } else {
                                            setLoading(true);
                                            setSubmitDisabled(true);
                                            setAlertMessage("Sending enquiry...");
                                            setShowAlert(true);
                                            handleSendEnquiry();
                                        }
                                    }}>Send Enquiry</LoadingButton>
                            </div>
                        </Box>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000}
                open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
            <Footer />
        </ThemeProvider>
    );
}